import {FC, useState, createContext, useContext, useMemo, useEffect, Dispatch, SetStateAction} from 'react'
import {
    ID,
    calculatedGroupingIsDisabled,
    calculateIsAllDataSelected,
    groupingOnSelect,
    initialListView,
    ListViewContextProps,
    groupingOnSelectAll,
    WithChildren, QUERIES,
} from '../../../../../../_metronic/helpers'
import {Task} from './_models'
import {useQueryResponse, useQueryResponseData} from './QueryResponseProvider'
import {useParams} from "react-router-dom";

type ExtraListViewContextProps = {
  tasks: Task[]
  setTasks: Dispatch<SetStateAction<Task[]>>
  updateCellData: (rowIndex: number, columnId: number, value: any) => void
  addRow: () => void
  removeRow: () => void
  isCreatingTask: boolean
  setIsCreatingTask: Dispatch<SetStateAction<boolean>>
}

type CustomListViewContextProps = ListViewContextProps & ExtraListViewContextProps

const extraInitialListView: ExtraListViewContextProps = {
  tasks: [],
  setTasks: () => {},
  updateCellData: () => {},
  addRow: () => {},
  removeRow: () => {},
  isCreatingTask: false,
  setIsCreatingTask: () => {},
}

interface IconUserModel {
  name?: string
  avatar?: string
  color?: string
  initials?: string
  task_responsible_id?: ID
}


const ListViewContext = createContext<CustomListViewContextProps>(Object.assign(initialListView, extraInitialListView))

const ListViewProvider: FC<WithChildren> = ({children}) => {
  const [selected, setSelected] = useState<Array<ID>>(initialListView.selected)
  const [itemIdForUpdate, setItemIdForUpdate] = useState<ID>(initialListView.itemIdForUpdate)
  const [taskIdForUpdate, setTaskIdForUpdate] = useState<ID>(initialListView.taskIdForUpdate)
  const [holidayIdForUpdate, setHolidayIdForUpdate] = useState<ID>(initialListView.holidayIdForUpdate)
  const {isLoading} = useQueryResponse()
  const data = useQueryResponseData()
  const [tasks, setTasks] = useState<Task[]>(data)
  const disabled = useMemo(() => calculatedGroupingIsDisabled(isLoading, data), [isLoading, data])
  const isAllSelected = useMemo(() => calculateIsAllDataSelected(data, selected), [data, selected])
  const {project_id} = useParams()
  const [isCreatingTask, setIsCreatingTask] = useState<boolean>(false)


    useEffect(() => {
    setTasks(data)
  }, [data])


  const updateCellData = (rowIndex: number, columnId: number, value: any) => {
    setTasks(tasks =>
      tasks.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...tasks[rowIndex],
            [columnId]: value,
          }
        }
        return row
      })
    )
  }


  const addRow = () => {

    const emptyRow = {
      id: tasks.length+1,
      name: "",
      status: "Backlog",
      color: "primary",
      users: [],
      usersAll: [],
      description: "",
      total_time: 0,
      play: 'pause',
      tags: []
    }

    setTasks(tasks => [...tasks, emptyRow])
    setIsCreatingTask(true)
  }
  const removeRow = () => {
    setTasks(tasks.filter(task => task.name !== ''))
    setIsCreatingTask(false)

  }

  return (
    <ListViewContext.Provider
      value={{
        selected,
        itemIdForUpdate,
        setItemIdForUpdate,
        taskIdForUpdate, 
        setTaskIdForUpdate,
        holidayIdForUpdate,
        setHolidayIdForUpdate,
        disabled,
        isAllSelected,
        onSelect: (id: ID) => {
          groupingOnSelect(id, selected, setSelected)
        },
        onSelectAll: () => {
          groupingOnSelectAll(isAllSelected, setSelected, data)
        },
        clearSelected: () => {
          setSelected([])
        },
        tasks,
        setTasks,
        updateCellData,
        addRow,
        removeRow,
        isCreatingTask,
        setIsCreatingTask
      }}
    >
      {children}
    </ListViewContext.Provider>
  )
}

const useListView = () => useContext(ListViewContext)

export {ListViewProvider, useListView}
