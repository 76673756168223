import {ID, Response} from '../../../../helpers'
import {date} from "yup";

interface IconUserModel {
  name: string
  avatar?: string
  color?: string
  initials?: string
  task_responsible_id?: ID
  user_id: ID
}

export type ResponseTask = {
  tasks: Task
  message?: string
  errors?: boolean
}

export type Users = {
  users?: Array<IconUserModel>
}

export type Tag = {
  id: number
  name: string
  instance_id: number
}

export type Task = {
  id?: ID
  name?: string,
  status?: string,
  status_name?: string,
  project_id?: string,
  project_name?: string,
  creator_id?: string,
  progress?: number,
  users?: Array<IconUserModel>
  description?: string,
  expected_date?: string,
  file?: Array<any>,
  checklist_concluded?: Array<any>,
  checklist_name?: Array<any>,
  sprintId?: ID
  tags?: Tag[]
}

export type User = {
  id?: ID
  task_onboarded: boolean
}

export type ResponseUser = {
  message: string
  errors: boolean
}

export type TasksQueryResponse = Response<Array<Task>>

export const initialTask: Task = {
  name: '',
  description : '',
  status : 'Backlog',
  project_id : '',
  project_name: '',
  creator_id : '',
  progress: 0,
  expected_date: '',
  file: [],
  checklist_concluded: [],
  checklist_name: [],
  tags: []
}
