/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState, useEffect, ChangeEvent, useRef} from 'react'
import {useListView} from '../../../core/ListViewProvider'
import {useMutation} from 'react-query'
import {ID} from '../../../../../../_metronic/helpers'
import {updateNameTask} from '../../../core/_requests'
import {Toast} from '../../../../../../_metronic/partials/widgets'
import {useIntl} from 'react-intl'

type Props = {
  task_id: ID
  name: string
  rowIndex: number
  columnId: number
}

const MyTaskNameCell: FC<Props> = ({task_id, name, rowIndex, columnId}) => {
  const [value, setValue] = useState<string>('')
  const ref = useRef<HTMLInputElement>(null)
  const {removeRow} = useListView()
  const intl = useIntl()
  const [lastValue, setLastValue] = useState(name)

  useEffect(() => {
    if (ref.current && name === '') {
      ref.current.focus()
    }
  }, [ref.current])

  useEffect(() => {
    setValue(name)
    setLastValue(name)
  }, [name])

  const validateRow = (isEnterPress: boolean = false) => {
    if (value !== name && value !== '' && name !== '') {
      updateNameTaskItem.mutateAsync(value.replaceAll('/', '-'))
    }
  }

  const updateNameTaskItem = useMutation(
    (value: string) => updateNameTask(task_id!, value.toString()),
    {
      onSuccess: () => {
        setLastValue(value)
        Toast.fire({
          icon: 'success',
          title: intl.formatMessage(
            {id: 'ALERT.LABEL.SUCCESS_TASK_UPDATE_ITEM'},
            {item: intl.formatMessage({id: 'TABLE.GENERAL.LABEL.TASK'})}
          ),
          customClass: {
            popup: 'bg-light-success',
            title: 'text-success',
          },
        })
      },
    }
  )

  const onBlur = () => {
    if (value == '') {
      setValue(lastValue)
      return
    }

    if (value !== name) {
      updateNameTaskItem.mutateAsync(value.replaceAll('/', '-'))
    }
  }

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value.replaceAll('/', '-'))
  }

  return (
    <div className='d-flex flex-column justify-content-center'>
      <div className='w-100 d-flex align-items-center position-relative my-1'>
        <input
          type='text'
          className='fw-normal text-gray-900 form-control bg-transparent border-transparent w-md-1200px border-hover-secondary'
          placeholder='Buscar tarefa'
          onBlur={onBlur}
          onChange={onChange}
          onKeyPress={(e) => {
            e.key === 'Enter' && validateRow()
          }}
          ref={ref}
          value={value}
        />

        {name === '' && value.length < 3 ? (
          <div
            onClick={() => {
              validateRow()
            }}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='30'
              height='30'
              fill='currentColor'
              className='bi bi-check-circle cursor-pointer'
              viewBox='0 0 16 16'
              style={{color: '#50b840', marginLeft: '20', cursor: 'not-allowed'}}
            >
              <path d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z' />
              <path d='M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z' />
            </svg>
          </div>
        ) : (
          name === '' &&
          value.length > 2 && (
            <div
              onClick={() => {
                validateRow()
              }}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='30'
                height='30'
                fill='currentColor'
                className='bi bi-check-circle cursor-pointer'
                viewBox='0 0 16 16'
                style={{color: '#50b840', marginLeft: '20'}}
              >
                <path d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z' />
                <path d='M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z' />
              </svg>
            </div>
          )
        )}

        {name === '' && (
          <div
            onClick={() => {
              removeRow()
            }}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='30'
              height='30'
              fill='currentColor'
              className='bi bi-x-circle svg-icon-1 ms-3 cursor-pointer'
              viewBox='0 0 16 16'
              style={{color: 'red'}}
            >
              <path d='M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z' />
              <path d='M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z' />
            </svg>
          </div>
        )}
      </div>
    </div>
  )
}

export {MyTaskNameCell}
