import {Children, Dispatch, MouseEvent, useEffect, useState} from 'react'
import {useMutation, useQuery, useQueryClient} from 'react-query'
import {Tag} from '../core/_models'
import {getTags} from '../core/_requests'
import Select, {components, GroupBase, MenuListProps, MultiValue, OptionProps} from 'react-select'
import {useAuth} from '../../../../../app/modules/auth'
import axios from 'axios'
import Swal from 'sweetalert2'
import clsx from 'clsx'

type Props = {
  selectedOptions: Tag[]
  setSelectedOptions: Dispatch<React.SetStateAction<Tag[]>>
  controlClass?: string
  isSearchable?: boolean
  actions?: boolean
}

const API_URL = process.env.REACT_APP_API_URL

export const TagsAutoComplete: React.FC<Props> = ({
  selectedOptions,
  setSelectedOptions,
  controlClass,
  isSearchable = true,
  actions = true,
}) => {
  const {currentUser} = useAuth()
  const [options, setOptions] = useState([] as Tag[])
  const [inputValue, setInputValue] = useState('')
  const queryClient = useQueryClient()
  const {data, isLoading} = useQuery(`tags`, async () => await getTags(), {
    cacheTime: 0,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  })

  useEffect(() => {
    if (data) setOptions(data)
  }, [data])

  const handleChange = (values: MultiValue<Tag>) => {
    const updatedTags: Tag[] = values.map((item) => item)
    setSelectedOptions(updatedTags)
  }

  const handleInputChange = (newInputValue: string) => {
    setInputValue(newInputValue)
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (
      event.key === 'Enter' &&
      (currentUser?.administrator || currentUser?.manager) &&
      inputValue.trim() !== ''
    ) {
      event.preventDefault()
      if (!inputValue.trim()) return

      if (
        !selectedOptions.some((option) => option.name.toLowerCase() === inputValue.toLowerCase())
      ) {
        const newTag = {name: inputValue} as Tag
        setSelectedOptions([...selectedOptions, newTag])
        setInputValue('')
      }
    }
  }

  const {mutateAsync: deleteTagFn, isLoading: isDeletingTag} = useMutation(
    async (id: number) => await axios.delete(`${API_URL}/api/tags/${id}/excluir`),
    {
      onSuccess: (deletedTag, id) => {
        queryClient.setQueryData<Tag[]>([`tags`], (data) => {
          if (!data) return []
          setSelectedOptions((prevOptions) => prevOptions.filter((tag) => tag.id != id))
          return data.filter((tag) => tag.id != id)
        })
      },
    }
  )

  async function handleDeleteTag(e: MouseEvent<HTMLElement, globalThis.MouseEvent>, id: number) {
    e.stopPropagation()
    const CurrentTheme = window.localStorage.getItem('kt_theme_mode_value') || ''
    const theme = CurrentTheme === 'dark' ? '#353b48' : '#fff'
    const tagInfos = (
      await axios.get(`${API_URL}/api/tags/info`, {
        params: {tag_ids: [id]},
      })
    ).data
    const response = await Swal.fire({
      title: 'Remoção de tags',
      html:
        'Você está prestes a remover as seguintes tags. Esta ação não pode ser desfeita: <br /><br />' +
        tagInfos
          .map(
            (tag: any) =>
              `<p>
                    Tag <b>"${tag.name}"</b>: possui <b>${tag.tasks_count} ${
                tag.tasks_count == 1 ? 'tarefa' : 'tarefas'
              }</b> e
                    <b>${tag.wiki_posts_count} ${
                tag.wiki_posts_count == 1 ? 'post associado' : 'posts associados'
              }</b>.
                  </p>`
          )
          .join(''),
      icon: 'warning',
      background: theme,
      showCloseButton: true,
      showCancelButton: true,
      confirmButtonText: 'Sim, pode excluir',
      cancelButtonText: 'Não, cancele!',
      reverseButtons: true,
    })

    if (response.isDenied || response.isDismissed) return

    deleteTagFn(id)
  }

  function handleEditTag(e: MouseEvent<HTMLElement, globalThis.MouseEvent>) {
    e.stopPropagation()
    window.open('/configs', '_blank')
  }

  const {Option} = components
  const ActionOption = (props: OptionProps<Tag, true, GroupBase<Tag>>) => (
    <Option {...props} className='d-flex align-items-center'>
      {props.data.name}
      <div className='d-flex ms-auto gap-4 align-items-center'>
        <i className='bi bi-pencil-square h-6' role='button' onClick={handleEditTag}></i>

        <i
          className='bi bi-trash text-danger h-6'
          role='button'
          onClick={(e) => handleDeleteTag(e, props.data.id)}
          style={{pointerEvents: isDeletingTag ? 'none' : 'auto', opacity: isDeletingTag ? 0.5 : 1}}
        ></i>
      </div>
    </Option>
  )

  const CustomMenuList = (props: MenuListProps<Tag, true, GroupBase<Tag>>) => {
    const tagExists = selectedOptions.some(
      (tag) => tag.name.toLowerCase() === inputValue.toLowerCase()
    )
    const shouldShowMessage = inputValue && !tagExists

    // Verifica se há filhos e se eles são uma lista de opções
    const hasOptions = Children.count(props.children) > 0

    return (
      <div style={{maxHeight: hasOptions ? '200px' : '0', overflowY: 'auto'}}>
        <components.MenuList {...props}>
          {shouldShowMessage && (
            <div style={{padding: '10px', color: '#999', textAlign: 'center'}}>
              Pressione Enter para adicionar a tag.
            </div>
          )}
          {tagExists && (
            <div style={{padding: '10px', color: '#999', textAlign: 'center'}}>
              Tag já adicionada.
            </div>
          )}
          {!hasOptions && !inputValue && (
            <div style={{padding: '10px', color: '#999', textAlign: 'center'}}>
              {currentUser?.administrator || currentUser?.manager
                ? 'Nenhuma tag disponível'
                : 'Nenhuma opção disponível'}
            </div>
          )}
          {props.children}
        </components.MenuList>
      </div>
    )
  }

  return (
    <Select
      closeMenuOnSelect={false}
      isMulti
      value={selectedOptions}
      isClearable={false}
      isSearchable={isSearchable}
      onInputChange={handleInputChange}
      onChange={handleChange}
      name='tags'
      options={options}
      hideSelectedOptions={false}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
      getOptionValue={(option) => (typeof option === 'string' ? option : option.name)}
      noOptionsMessage={() => (options.length > 0 || inputValue ? '' : 'Nenhuma tag disponível')}
      onKeyDown={handleKeyDown}
      inputValue={inputValue}
      placeholder={'Selecione as tags'}
      components={
        actions && (currentUser?.administrator || currentUser?.manager)
          ? {Option: ActionOption, MenuList: CustomMenuList}
          : undefined
      }
      classNames={{
        control: (state) => clsx('form-control form-control-solid p-1', controlClass),
        input: (state) => 'text-dark',
        singleValue: (state) => 'text-dark',
        menu: (state) => 'bg-body',
      }}
      styles={{
        option: (styles, {data, isDisabled, isFocused, isSelected}) => {
          return {
            ...styles,
            backgroundColor: isDisabled
              ? undefined
              : isSelected
              ? '#369bff70'
              : isFocused
              ? '#369bff70'
              : undefined,
            color: isDisabled ? '#ccc' : isSelected ? '#181C32' : undefined,
          }
        },
        multiValue: (provided: any) => ({
          ...provided,
          borderRadius: '12px',
          backgroundColor: '#007bff',
          padding: '0px 4px',
        }),
        multiValueLabel: (provided: any) => ({
          ...provided,
          color: 'white',
        }),
        multiValueRemove: (provided: any) => ({
          ...provided,
          padding: '0px',
          margin: '0px',
          color: 'white',
          cursor: 'pointer',
          ':hover': {
            backgroundColor: 'none',
          },
        }),
        menuList: (provided) => ({
          ...provided,
          maxHeight: 160, 
          overflowY: 'auto',
        }),
      }}
    />
  )
}
