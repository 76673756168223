import {FC, useState, useMemo, useEffect, ChangeEvent} from 'react'
import {useIntl} from 'react-intl'
import clsx from 'clsx'
import {Project} from '../../../../app/modules/project/components/core/_models'
import {InputCurrency, KTSVG, QUERIES} from '../../../helpers'
import {Modal} from 'react-bootstrap'
import {getProjectCycleView} from '../../../../app/modules/project/components/overview/components/overview-cycle/core/_requests'
import {useParams} from 'react-router-dom'
import {useQuery} from 'react-query'
import {ProjectCycleView} from '../../../../app/modules/project/components/overview/components/overview-cycle/core/_models'
import {getProjectById} from '../../../../app/modules/project/components/core/_requests'

type Props = {
  updateData: (fieldsToUpdate: Partial<Project> & {cycleOption?: string; cycleId?: number}) => void
  handleBlur?: ({
    cycleId,
    cycleOption,
    cycle,
    budget,
    budgeted_hours,
  }: Partial<Project> & {cycleId?: number; cycleOption?: string}) => void
  value: string | number
  inputValue?: string
  label?: string
  field: string
  hasModal?: boolean
}

const InputCurrencyEdit: FC<Props> = ({
  updateData,
  handleBlur,
  value,
  inputValue,
  label,
  field,
  hasModal = false,
}) => {
  const intl = useIntl()
  const [isChecked, setIsChecked] = useState(false)
  const [editableField, setEditableField] = useState(false)
  const [dataChanged, setDataChanged] = useState(false)
  const [blured, setBlured] = useState(true)
  const [openModal, setOpenModal] = useState(false)
  const oldInputValue = useMemo(() => (inputValue ? inputValue : value), [blured])
  const {project_id} = useParams()
  const [cycleId, setCycleId] = useState<number>()
  const [currentValue, setCurrentValue] = useState<string>()

  const {
    isLoading,
    data: project,
    refetch,
    error,
  } = useQuery(
    `${QUERIES.PROJECTS_LIST}-project-${project_id}`,
    () => getProjectById(parseInt(project_id!)),
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
    }
  )

  console.log(project?.type);

  const {data} = useQuery(
    `${QUERIES.PROJECTS_LIST}-project-cycle-view-${project_id}`,
    () => {
      return project?.type != 0 ? {} as ProjectCycleView: getProjectCycleView(parseInt(project_id!), '')
    },
    {cacheTime: 0, keepPreviousData: true, refetchOnWindowFocus: false}
  )

  useEffect(() => {
    if (openModal && data?.cycles_filter) setCycleId(data?.cycles_filter[0].id)
  }, [openModal])

  const handleCloseEditableField = () => {
    if (editableField) {
      if (oldInputValue != null) {
        updateData({[field]: oldInputValue})
      }
    }
    setEditableField(!editableField)
  }

  function handleComplete() {
    if (handleBlur)
      handleBlur({
        cycleOption: isChecked ? 'allCycles' : undefined,
        cycleId: cycleId,
        [field]: currentValue,
      })
    setBlured(true)
    setOpenModal(false)
    setIsChecked(false)
    setCycleId(undefined)
    setCurrentValue(undefined)
  }

  function handleClose() {
    updateData({[field]: oldInputValue, cycleOption: 'currentCycle', cycleId: undefined})
    setOpenModal(false)
    setIsChecked(false)
    setCycleId(undefined)
    setCurrentValue(undefined)
  }

  function handlePeriodChange(e: ChangeEvent<HTMLSelectElement>) {
    setCycleId(parseInt(e.target.value))
  }

  return (
    <div
      className='d-flex align-items-center mb-6'
      onBlur={(e) => {
        if (editableField) {
          const currentTarget = e.currentTarget
          requestAnimationFrame(() => {
            if (!currentTarget.contains(document.activeElement)) {
              setEditableField(false)
              if (dataChanged && hasModal && handleBlur) setOpenModal(true)
              if (dataChanged && !hasModal && handleBlur) {
                handleBlur({})
                setBlured(true)
              }
            }
            setDataChanged(false)
          })
        }
      }}
    >
      <span className='fw-bold text-dark me-1'>{label}</span>
      {editableField ? (
        <InputCurrency
          className='form-control form-control-sm form-control-solid mw-150px'
          autoFocus
          value={inputValue ?? value}
          onChange={(e) => {
            if (value !== e.target.value.toString()) {
              updateData({
                [field]: e.target.value.replace('.', '').replace('.', '').replace(',', '.'),
              })
              setCurrentValue(e.target.value.replace('.', '').replace('.', '').replace(',', '.'))
              setDataChanged(true)
              return
            }
            setDataChanged(false)
          }}
        />
      ) : (
        <span className='text-dark me-2'>
          {new Intl.NumberFormat(intl.locale, {minimumFractionDigits: 2}).format(
            parseFloat(value?.toString())
          )}
        </span>
      )}
      <button onClick={handleCloseEditableField} className='btn p-0'>
        <i
          className={clsx(
            'cursor-pointer fs-5 bi',
            editableField ? 'bi-x-square-fill ms-4' : 'bi-pencil'
          )}
        ></i>
      </button>

      <Modal
        data-backdrop='static'
        tabIndex={-1}
        role='dialog'
        show={openModal}
        dialogClassName='modal-dialog-centered'
        aria-hidden='true'
        onHide={() => setOpenModal(false)}
      >
        <Modal.Dialog className='my-0'>
          <Modal.Header className='d-flex align-items-center py-2 border-bottom'>
            <Modal.Title className='fw-bold'>
              Confirmação de alteração de orçamento mensal
            </Modal.Title>
            <div className='d-flex'>
              <div
                className='btn btn-icon btn-sm btn-light-primary'
                data-bs-dismiss='modal'
                onClick={handleClose}
              >
                <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
              </div>
            </div>
          </Modal.Header>
          <Modal.Body>
            <div>
              <p className='m-0'>
                Por favor, confirme sua alteração de orçamento mensal. Atualmente, o orçamento
                mensal deste projeto é de <strong>{oldInputValue} reais.</strong>
              </p>
              <p>
                Você pode optar para atualizar o orçamento mensal a partir de um determinado ciclo,
                ou levando em consideração desde o início do projeto.
              </p>
            </div>

            {!isChecked ? (
              <div className='col-md-6 d-flex align-items-center pe-md-0'>
                <label className='label-form me-3 fs-5' htmlFor='period'>
                  {intl.formatMessage({id: 'PROJECT.LABEL.CYCLE'})}
                </label>
                <select
                  className='form-select form-select-sm'
                  id='period'
                  onChange={(e) => handlePeriodChange(e)}
                  value={cycleId}
                >
                  {data?.cycles_filter! ? (
                    data?.cycles_filter!.map((item) => (
                      <option value={item.id} key={item.id}>{`${new Date(
                        item.begin_date
                      ).toLocaleDateString(intl.locale, {
                        timeZone: 'UTC',
                      })} a ${new Date(item.end_date).toLocaleDateString(intl.locale, {
                        timeZone: 'UTC',
                      })}`}</option>
                    ))
                  ) : (
                    <option value=''>
                      {intl.formatMessage({id: 'PROJECT.LABEL.NO_CYCLE_DATA'})}
                    </option>
                  )}
                </select>
              </div>
            ) : null}

            <div className='form-check form-check-sm form-check-custom form-check-solid mt-4'>
              <input
                role='button'
                className='form-check-input'
                type='checkbox'
                id='allCycles'
                checked={isChecked}
                onChange={() => setIsChecked((val) => !val)}
              />
              <label className='form-check-label' htmlFor='allCycles' role='button'>
                Aplicar essa alteração desde a data de início do projeto
              </label>
            </div>

            <div className='alert alert-warning mt-6 mb-0' role='alert'>
              *Atenção: O ciclo do projeto significa um período de 30 dias de atividades para o
              projeto. Este é um conceito para projetos recorrentes e é utilizado para entender o
              real esforço alocado para este projeto durante um determinado período.*
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type='button' onClick={handleClose} className='btn btn-outline-secondary py-2'>
              Cancelar
            </button>
            <button
              type='button'
              onClick={handleComplete}
              className='btn btn-primary py-2'
              disabled={!isChecked && !cycleId}
            >
              Alterar orçamento mensal
            </button>
          </Modal.Footer>
        </Modal.Dialog>
      </Modal>
    </div>
  )
}

export {InputCurrencyEdit}
